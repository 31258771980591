import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import collageRo from '../../images/other-art/collage-ro.png'
import "../layout.css"

import img1 from '../../images/other-art/collage/1.jpg'
import img2 from '../../images/other-art/collage/2.jpg'
import img3 from '../../images/other-art/collage/3.jpg'
import img4 from '../../images/other-art/collage/4.jpg'
import img5 from '../../images/other-art/collage/5.jpg'
import img6 from '../../images/other-art/collage/6.jpg'
import img7 from '../../images/other-art/collage/7.jpg'
import img8 from '../../images/other-art/collage/8.jpg'
import img9 from '../../images/other-art/collage/9.jpg'
// import img10 from '../../images/other-art/collage/10.jpg'
import img11 from '../../images/other-art/collage/11.jpg'
import img12 from '../../images/other-art/collage/12.jpg'
import img13 from '../../images/other-art/collage/13.jpg'
import img14 from '../../images/other-art/collage/14.jpg'
import img15 from '../../images/other-art/collage/15.jpg'

export default function Collage() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img11, img12, img13, img14, img15]
    return (
        <GalleryBackground title="Collage" imgSrc={collageRo}>
            <GalleryGridFancy imgArray={images}/>
        </GalleryBackground>
    )
}
